.string-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;

    padding: 0;

    label {
        margin-top: 0.5rem;
    }

    .string-list-fields {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;
        gap: 0.5rem;

        .string-list-field {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;

            input {
                width: 25rem;
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                padding: 0;
                border: none;
                border-radius: 5px;

                img {
                    height: 2rem;
                    width: 2rem;
                    opacity: 0.6;
                }
            }
        }
    }
}