.labeling-requests {
    width: 100%;

    .labeling-list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, 8rem);
        grid-auto-rows: 8rem;
        gap: 0.5rem;
        padding: 0.5rem;

        .overflow-square {
            position: relative;
            background-color: #3b4249;
            width: 100%;
            height: 100%;
            border-radius: 1rem;

            .overflow-number {
                position:absolute;
                width: 100%;
                top: calc(50% - 1rem);
                text-align: center;
                font-size: 1.5rem;
                color: white;
                margin: 0;

            }

            &:hover {
                cursor: pointer;
                scale: 1.02;
                opacity: 0.9;
                animation-name: scale_up;
                animation-duration: 0.2s;
            }
        }
    }

    @keyframes scale_up {
        from {scale: 1; opacity: 1;}
        to {scale: 1.02; opacity: 0.9;}
    }
}