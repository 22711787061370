.labeling-conflicts {
    width: 100%;

    .labeling-list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, 8rem);
        grid-auto-rows: 8rem;
        gap: 0.5rem;
        padding: 0.5rem;
    }

    .labeling-conflicts-category {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 0;
        font-size: 1.2rem;

        img {
            height: 1.5rem;
        }
    }

    .filter-dropdown {
        height: 2rem;
        border: none;
        background-color: #FDFDFD;
        font: inherit;
        border-radius: 0.5rem;
        padding: 0 0.5rem;
    }
}